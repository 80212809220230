<template>
  <v-container fluid>
    <h3 class="page-title">格主一番賞管理</h3>
    <v-row>
      <v-col cols="6" sm="4" md="2">
        <v-select
          label="審核狀態"
          v-model="approvalStatus"
          :items="[
            { text: '全部', value: null },
            { text: '待審核', value: 'pending' },
            { text: '通過', value: 'approved' },
            { text: '取消', value: 'canceled' },
          ]"
          outlined
          dense
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <v-select
          label="店點"
          v-model="branch"
          :items="branches"
          outlined
          dense
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="6" sm="4" md="3">
        <v-text-field
          v-model="keyword"
          label="關鍵字"
          outlined
          dense
          clearable
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filterData"
          :mobile-breakpoint="0"
          item-key="index"
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [30, 100, -1],
          }"
        >
          <template v-slot:item.approvalStatus="{ item }">
            <v-select
              v-model="item.approvalStatus"
              dense
              hide-details
              outlined
              :items="approvalStatusOptions"
              @change="update(item, 'approvalStatus')"
              :style="{ 'min-width': '110px' }"
            ></v-select>
          </template>
          <template v-slot:item.isAvailable="{ item }">
            <v-checkbox
              class="empty-label"
              v-model="item.isAvailable"
              @change="update(item, 'isAvailable')"
              :disabled="item.approvalStatus !== 'approved'"
            ></v-checkbox>
          </template>
          <template v-slot:item.isOnline="{ item }">
            <v-checkbox
              class="empty-label"
              v-model="item.isOnline"
              disabled
            ></v-checkbox>
          </template>
          <template v-slot:item.status="{ item }">
            <span
              v-if="
                item.sold === item.count ||
                item.rewards.every((item) => item.hit)
              "
              class="red--text"
              >完售
            </span>
          </template>
          <template v-slot:item.plans="{ item }">
            <div v-for="plan of item.plans">
              {{ plan.count }}抽 - ${{ plan.amount }}
            </div>
          </template>
          <template v-slot:item.rewards="{ item }">
            <div v-for="reward of item.rewards">
              <span :class="{ 'red--text': reward.hit }"
                >#{{ reward.number }} - {{ reward.reward }}</span
              >
            </div>
          </template>
          <template v-slot:item.sold="{ item }">
            <div :class="{ 'red--text': item.sold === item.count }">
              {{ item.sold }} / ${{ item.amount }}
            </div>
          </template>
          <template v-slot:item.userID="{ item }">
            <div v-if="item.userID">
              {{
                `${mapUser[item.userID]?.name || ""} (${
                  mapUser[item.userID]?.phone || ""
                })`
              }}
            </div>
            <div v-else>---</div>
          </template>

          <template v-slot:item.update="{ item }">
            <v-btn text color="primary" @click="openDialog(item, 'ichiban')"
              >編輯</v-btn
            >
          </template>
          <template v-slot:item.display="{ item }">
            <v-btn text color="primary" @click="openDialog(item, 'display')">
              展示圖
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogIchiban
      v-if="dialog.ichiban"
      v-model="dialog.ichiban"
      :item="selectedItem"
      @load="load"
      :branches="accessBranch"
      :owners="owners"
      :users="users"
    ></DialogIchiban>
    <DialogIchibanDisplay
      v-if="dialog.display"
      v-model="dialog.display"
      :item="selectedItem"
      @load="load"
      :branches="accessBranch"
    ></DialogIchibanDisplay>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import _ from "lodash";
import util from "@/mixins/util.vue";
import DialogIchiban from "@/components/dashboard/ichiban/DialogIchiban.vue";
import DialogIchibanDisplay from "@/components/dashboard/ichiban/DialogIchibanDisplay.vue";
// import DialogIchibanOwner from "@/components/dashboard/ichiban/DialogIchibanOwner.vue";

export default {
  name: "Ichiban",
  mixins: [util],
  components: {
    DialogIchiban,
    DialogIchibanDisplay,
    // DialogIchibanOwner,
  },
  data: () => ({
    data: [],
    owners: [],
    users: [],
    branch: null,
    isOnline: null,
    isAvailable: null,
    isSoldout: false,
    approvalStatus: null,
    dialog: {
      ichiban: false,
      display: false,
      owner: false,
    },
    // selectedItem: null,
    selectedItemId: "",
    uploadImageItem: null,
    keyword: "",
    approvalStatusOptions: [
      {
        text: "待審核",
        value: "pending",
      },
      {
        text: "通過",
        value: "approved",
      },
      {
        text: "取消",
        value: "canceled",
      },
    ],
    approvalStatusMap: {
      pending: "待審核",
      approved: "通過",
      canceled: "取消",
    },
    headers: [
      {
        text: "審核狀態",
        value: "approvalStatus",
        align: "center",
        sortable: false,
      },
      {
        text: "上架",
        value: "isAvailable",
        align: "center",
        sortable: true,
        width: 80,
      },
      {
        text: "線上",
        value: "isOnline",
        align: "center",
        sortable: true,
        width: 80,
      },
      // {
      //   text: "完售",
      //   value: "status",
      //   align: "center",
      //   sortable: true,
      // },
      { text: "店點", value: "branch", align: "left", sortable: false },
      { text: "編號", value: "key", align: "left", sortable: false },
      { text: "一番賞名稱", value: "name", align: "left", sortable: false },
      { text: "抽數", value: "count", align: "center", sortable: true },
      // { text: "已抽", value: "sold", align: "center", sortable: true },
      // { text: "中獎", value: "win", align: "center", sortable: true },
      { text: "價格", value: "plans", align: "left", sortable: true },
      { text: "獎項", value: "rewards", align: "left", sortable: false },
      {
        text: "安慰獎",
        value: "consolationPrize",
        align: "left",
        sortable: false,
      },
      { text: "格主", value: "userID", align: "center", sortable: false },
      { text: "", value: "update", align: "center", sortable: false },
      { text: "", value: "display", align: "center", sortable: false },
    ],
  }),
  computed: {
    filterData() {
      let data = this.data;
      if (this.keyword) {
        data = data.filter(
          (item) =>
            item.name.includes(this.keyword) || item.key.includes(this.keyword)
        );
      }
      if (this.branch) {
        data = data.filter((item) => item.branch === this.branch);
      }
      if (this.approvalStatus !== null) {
        data = data.filter(
          (item) => item.approvalStatus === this.approvalStatus
        );
      }
      return data;
    },
    mapUser() {
      return _.keyBy(this.users, "userID");
    },
    branches() {
      if (this.accessBranch.length > 1) {
        return [{ text: "全部", value: null }, ...this.accessBranch];
      }
      return this.accessBranch;
    },
    selectedItem() {
      return this.data.find((item) => item._id === this.selectedItemId);
    },
  },
  created() {
    this.getOwners();
    this.getUsers();
    this.$nextTick(() => {
      this.ready = true;
      this.load();
    });
  },
  methods: {
    async addRecord() {
      // this.selectedItem = null;
      this.selectedItemId = "";

      this.dialog.ichiban = true;
    },
    async load() {
      if (!this.ready) return;
      const { data } = await this.axios.get(`/ichiban`, {
        params: { isCreatedByClient: true },
      });
      data.reverse();
      this.data = data;
    },
    async update(item, key) {
      this.$vloading.show();
      await this.axios.put(`/ichiban/${item._id}`, {
        [key]: item[key],
      });
      await this.load();

      this.$vloading.hide();
    },
    async openDialog(item, type) {
      // this.selectedItem = item;
      this.selectedItemId = item._id;
      this.dialog[type] = true;
    },
    async getOwners() {
      const { data } = await this.axios.get("/ichibanOwner");
      this.owners = data;
    },
    async getUsers() {
      const { data } = await this.axios.get("/users", {
        params: { store: "@695yimoc" },
      });
      this.users = data.sort(this.sortDateDesc("createdAt"));
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.empty-label {
  position: relative;

  ::v-deep .v-input__slot {
    justify-content: center;
  }
}
.typeWidth {
  width: 140px;
}
</style>
